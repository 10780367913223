<template>
    <div class="orderStates">
        <div class="content">
            <div class="title">{{ elec_order_status[orderDetail.status] }}</div>
            <div class="detail">
                <div class="detailTilte">
                    <img
                        :src="orderDetail.icon_path"
                        class="detailImg">
                    <div class="detaText">{{ orderDetail.product_name }}</div>
                </div>
                <div class="detailCon">
                    <div class="conLi">
                        <span class="liName">充值账号</span>
                        <span class="liText">{{ orderDetail.charge_account }}</span>
                    </div>
                    <div class="conLi">
                        <span class="liName">订单编号</span>
                        <div class="liCopy"><span>{{ orderDetail.order_id }}</span>
                            <div class="Copy Copy2"
                                 :data-clipboard-text="orderDetail.order_id" @click="copy"
                            >复制
                            </div>
                        </div>
                    </div>
                    <div class="conLi"><span class="liName">下单时间</span> <span
                        class="liText">{{ orderDetail.create_time }}</span>
                    </div>
                    <div class="conLi"><span class="liName">充值数量</span> <span class="liText">x1</span></div>
                    <div class="conLi"><span class="liName">支付金额</span> <span
                        class="liText">{{ orderDetail.price / 100 }}</span></div>
                    <div class="conLi" v-if="orderDetail.refund_state > 0"><span class="liName">退款状态:</span> <span
                        class="liText">{{['退款成功', '退款失败','退款中','退款中'][orderDetail.refund_state - 1]}}</span></div>
                    <div class="conLi" v-if="orderDetail.reason"><span class="liName">失败原因</span> <span class="liText">{{ orderDetail.reason }}</span>
                    </div>
                    <div class="conLi" style="color:red;" v-if="orderDetail.refund_state == 3 || orderDetail.refund_state == 4">
                        充值失败，将在24小时内原路退回到您缴费账户
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
import {Toast} from 'vant';
import {elecOrderDetail} from "@/service/electricity"
import {elec_order_status} from "@/utils/enum"

export default {
    data() {
        return {
            orderDetail: {},
            elec_order_status
        }
    },
    methods: {
        async getData() {
            let response = await elecOrderDetail({order_id: this.$route.params.order_id})
            if (response.code === 200) {
                this.orderDetail = response.data
            }

        },
        copy() {
            var clipboard = new Clipboard('.Copy2')
            clipboard.on('success', () => {
                console.log('复制成功')
                //  释放内存
                Toast("已成功复制到剪贴板")
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持复制')
                // 释放内存
                clipboard.destroy()
            })
        }
    },
    mounted() {
        console.log('mounted')
        this.getData()
    }
}
</script>
<style scoped>
.orderStates {
    height: 100vh;
    background-color: #f6f7f7;
}

.orderStates .content {
    padding: 0 0.48rem;
}

.orderStates .content .title {
    color: #333;
    font-size: 0.88rem;
    line-height: 1.2rem;
    padding-top: 0.48rem;
    padding-bottom: 0.559995rem;
}

.orderStates .content .detail {
    background-color: #fff;
    border-radius: 0.319995rem;
}

.orderStates .content .detail .detailTilte {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 1.36rem;
    padding-left: 0.6rem;
    padding-bottom: 0.880005rem;
}

.orderStates .content .detail .detailTilte .detailImg {
    width: 0.96rem;
    height: 0.76rem;
}

.orderStates .content .detail .detailTilte .detaText {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 0.280005rem;
    font-size: 0.64rem;
    color: #333;
}

.orderStates .content .detail .detailCon {
    padding: 0 0.48rem;
}

.orderStates .content .detail .detailCon .conLi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.64rem 0;
    color: #666;
    font-size: 0.559995rem;
}

.orderStates .content .detail .detailCon .conLi .liText {
    color: #333;
}

.orderStates .content .detail .detailCon .conLi .liCopy {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.orderStates .content .detail .detailCon .conLi .liCopy .Copy {
    margin-left: 0.24rem;
    width: 1.5999rem;
    height: 0.72rem;
    text-align: center;
    border-radius: 0.24rem;
}

.orderStates .content .detail .detailCon .conLi .liCopy .Copy1 {
    color: #68400b;
    background: #fffaf2;
    border: 0.02rem solid #efcf9b;
}

.orderStates .content .detail .detailCon .conLi .liCopy .Copy2 {
    background: rgba(47, 202, 255, .1);
    border: 0.02rem solid #00a6ff;
    color: #00a6ff;
}

.orderStates .content .detail .detailCon .conLi + .conLi {
    border-top: 0.02rem dashed #dfdfdf;
}

.orderStates .content .button {
    width: 100%;
    margin-top: 0.96rem;
    background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
    background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
    background: linear-gradient(90deg, #f0d19e, #e6ba79);
    border-radius: 0.319995rem;
    color: #68400b;
    font-size: 0.64rem;
}

.orderStates .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 1.99995rem;
    background-color: #fff;
}

.orderStates .footer .btn {
    width: calc(100vw - 0.4rem);
    height: 1.59999rem;
    font-size: 0.72rem;
    background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
    background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
    background: linear-gradient(90deg, #f0d19e, #e6ba79);
    border-radius: 0.319995rem;
    color: #68400b;
}

.orderStates .van-popup {
    border-radius: 0.24rem;
}

.orderStates .codeModal {
    background: none;
    height: 13.5rem;
}

.orderStates .codeModal .close {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    bottom: 0.9rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.orderStates .codeContent {
    background: #fff;
    width: 11.25rem;
    height: 6.6rem;
    position: relative;
    border-radius: 0.3rem;
    padding: 0.15rem 0.45rem 0.6rem;
}

.orderStates .codeContent .title {
    text-align: center;
    line-height: 1.5rem;
    color: #333;
    font-size: 0.6rem;
}

.orderStates .codeContent .codeListcenter {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 7.5rem;
}

.orderStates .codeContent .codeList .codeItem {
    background: #f8f8f8;
    border-radius: 0.09rem;
    padding: 0.45rem 0.225rem;
    margin-bottom: 0.225rem;
}

.orderStates .codeContent .codeList .codeItem .titleCode {
    font-size: #333;
    font-size: 0.51rem;
}

.orderStates .codeContent .codeList .codeItem .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0.75rem;
    margin-top: 0.45rem;
}

.orderStates .codeContent .codeList .codeItem .card span {
    color: #999;
}

.orderStates .codeContent .codeList .codeItem .card p {
    color: #333;
    width: 6.9rem;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.orderStates .codeContent .codeList .codeItem .card .cardIn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.orderStates .codeContent .codeList .codeItem .password {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0.75rem;
    margin-top: 0.6rem;
}

.orderStates .codeContent .codeList .codeItem .password span {
    color: #999;
}

.orderStates .codeContent .codeList .codeItem .password p {
    color: #333;
    width: 6.9rem;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.orderStates .codeContent .codeList .codeItem .password .passwordIn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.orderStates .codeContent .codeList .codeItem .copy {
    color: #5a3309 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.05rem;
    height: 1.05rem;
    padding: 0 0.45rem;
    border-radius: 1.05rem;
    background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
    background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
    background: linear-gradient(90deg, #f0d19e, #e6ba79);
}

</style>
